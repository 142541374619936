import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'GtrRegistrationModuleNewOptionGroupView'
})
export default class GtrRegistrationModuleNewOptionGroupView extends Vue {
  data () {
    return {
      submitting: false,
      optionGroup: {
        name: null,
        blank_first: 0,
        has_cap: 0
      },
      display_order: 0
    }
  }

  async submit () {
    const form = this.$refs.createOptionGroupForm as HTMLFormElement
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          data: {
            name: this.$data.optionGroup.name,
            blank_first: this.$data.optionGroup.blank_first,
            has_cap: this.$data.optionGroup.has_cap,
            display_order: this.$data.display_order
          }
        }
        const response = await this.$store.dispatch('option/createOptionGroup', payload)
        Container.get(Notification).success('Option group successfully created.')
        this.$router.push({ name: 'level-two.modules.registration.options.edit', params: { option_uuid: response.data.uuid } })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
